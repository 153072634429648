const config = {
  "broker": "1",
  "name": "Portuguese",
  "registrationBundle": "portuguese",
  "defaultCountryName": "Brazil",
  "defaultCountryCode": "BR",
  "fxgeoip_code": "BR",
  languageCode: 'pt',
  languageName:'Portuguese',
  spokenLanguageCode:'pt',
  direction:'ltr',
  footerDisclaimer:'1142',
  brand: 'iforex'
};
module.exports = config;